import PropTypes from 'prop-types';
import React from 'react';
import { useState, useEffect } from 'react';
// material-ui
import { makeStyles } from '@material-ui/styles';
import { Divider, List, Typography } from '@material-ui/core';

// project imports
import NavItem from './../NavItem';
import NavCollapse from './../NavCollapse';

// style constant
const useStyles = makeStyles((theme) => ({
    menuCaption: {
        ...theme.typography.menuCaption
    },
    subMenuCaption: {
        ...theme.typography.subMenuCaption
    },
    menuDivider: {
        marginTop: '2px',
        marginBottom: '10px'
    }
}));

//-----------------------|| SIDEBAR MENU LIST GROUP ||-----------------------//

const NavGroup = ({ item }) => {
    const classes = useStyles();

    const [permissions, setPermissions] = useState('');
    const [test, setTest] = useState(false);
    useEffect(() => {
        getPermission();
    }, []);
    const getPermission = () => {
        
        const data = JSON.parse(localStorage.getItem('permissions'))
        
        console.log(data, 'permissions data')
        setPermissions(data)
        console.log(permissions, 'permiddions')
        console.log(permissions.dashboard, 'permiddions')
    }

    // menu list collapse & items
    const items = item.children.map((menu) => {
        console.log(menu, 'menu consle')
        switch (menu.type) {
            case 'collapse':
                return permissions[menu.id] === true ? <NavCollapse key={menu.id} menu={menu} level={1} /> : '';
            case 'item':
                return permissions[menu.id] === true ? <NavItem key={menu.id} item={menu} level={1} /> : '';
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        {permissions[menu.id]}
                    </Typography>
                );
        }
    });

    return (
        <React.Fragment>
            <List
                subheader={
                    item.title && (
                        <Typography variant="caption" className={classes.menuCaption} display="block" gutterBottom>
                            {item.title}
                            {item.caption && (
                                <Typography variant="caption" className={classes.subMenuCaption} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            <Divider className={classes.menuDivider} />
        </React.Fragment>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
