import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';

import { ProtectedRoute } from "./protected.route";

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
const Dashboardn = Loadable(lazy(() => import('../views/dashboardn')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));
const ClaimsLog = Loadable(lazy(() => import('../views/claims-log')));
const ClaimHistory = Loadable(lazy(() => import('../views/claimHistory')));
const DenialHistory = Loadable(lazy(() => import('../views/denialHistory')));
const OpinionHistory = Loadable(lazy(() => import('../views/opinionHistory')));
const InvestigationHistory = Loadable(lazy(() => import('../views/investigationHistory')));
const PaymentconfirmationHistory = Loadable(lazy(() => import('../views/ppcHistory')));



const reviewdServices = Loadable(lazy(() => import('../views/reviewdServices')));

const article = Loadable(lazy(() => import('../views/article')));
const MemberEnquiry = Loadable(lazy(() => import('../views/MemberEnquiry')));
const Reports = Loadable(lazy(() => import('../views/Reports')));
const ClaimEnquiry = Loadable(lazy(() => import('../views/ClaimEnquiry')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();
    const [permissions, setPermissions] = useState({
        dashboard : true,
        ClaimLog : false,
        MemberEnquiry : false,
        ClaimsOverView : false,
        Reports : false,
    });
    useEffect(() => {
        getPermission();
    }, []);
    const getPermission = () => {
        const data = JSON.parse(localStorage.getItem('permissions'))
        setPermissions(data)
    }

    return (
        <Route
            path={[
                '/dashboard/default',
                '/claims-log',
                '/MemberEnquiry',
                '/ClaimEnquiry',
                '/reviewdServices',
                '/claimHistory',
                '/denialHistory',
                '/opinionHistory',
                '/investigationHistory',  
                '/paymentconfirmationHistory',         
                '/Reports'
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <ProtectedRoute path="/dashboard" component={Dashboardn} />
                    <ProtectedRoute path="/MemberEnquiry" component={MemberEnquiry} /> 
                    <ProtectedRoute path="/Reports" component={Reports} />
                    <ProtectedRoute path="/ClaimEnquiry" component={ClaimEnquiry} /> 
                    <ProtectedRoute path="/reviewdServices" component={reviewdServices} /> 
                    <ProtectedRoute path="/claims-log" component={ClaimsLog} />
                    <ProtectedRoute path="/claimHistory" component={ClaimHistory} />
                    <ProtectedRoute path="/denialHistory" component={DenialHistory} />
                    <ProtectedRoute path="/opinionHistory" component={OpinionHistory} />
                    <ProtectedRoute path="/investigationHistory" component={InvestigationHistory} />
                    <ProtectedRoute path="/paymentconfirmationHistory" component={PaymentconfirmationHistory} />
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
