import PropTypes from 'prop-types';
import React from 'react';
// import { useEffect } from 'react';
import { useState, useEffect } from 'react';
// material-ui
import { makeStyles } from '@material-ui/styles';
import { Avatar, Box, ButtonBase } from '@material-ui/core';
import * as CryptoJS from 'crypto-js';
// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
// assets
import { IconMenu2 } from '@tabler/icons';
import { removeUserSession, getUser } from '../../../utils/Auth';
import './index.css';
import tataLogo from'../../../assets/images/tataaig.jpg';
import mchiLogo from'../../../assets/images/mchi.png';
import relianceLogo from'../../../assets/images/reliance.png';
import adityaBirlaLogo from'../../../assets/images/ad1.png';
import nivabupa from '../../../assets/images/nivabupa1.png';
import starHealthLogo from '../../../assets/images/star.png';
import royalSundaramLogo from '../../../assets/images/royalSundaram.jpg';
import Image from "../../../utils/image";


// style constant
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    headerAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        transition: 'all .2s ease-in-out',
        background: theme.palette.secondary.light,
        color: theme.palette.secondary.dark,
        '&:hover': {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.light
        }
    },
    boxContainer: {
        width: '228px',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            width: 'auto'
        }
    }
}));

//-----------------------|| MAIN NAVBAR / HEADER ||-----------------------//

const Header = ({ handleLeftDrawerToggle }) => {
    const classes = useStyles();
    const user_name = "Hi," + " " + localStorage.getItem('firstName') + " " + localStorage.getItem('lastName')
    const clientId = localStorage.getItem('clientIDE');
    const MINUTE_MS = 54000;

    
    const [logoImage, setLogoImage] = useState('');
    const [logoImageExtrcted, setLogoImageExtrcted] = useState('');
    const [fullUrl, setFullUrl] = useState('');


    useEffect(() => {
        getLogo()
      }, []);
      useEffect(() => {
        const interval = setInterval(() => {
            // console.log('Logs every minute');

            const user = {
                "clientId": localStorage.getItem('clientID'),
                "redirectUri": localStorage.getItem('redirectUri'),
                "userId": localStorage.getItem('userId'),
                "token": localStorage.getItem('oAuthtoken')
              }

              const encuser = {
                encryptedData: CryptoJS.AES.encrypt(
                  JSON.stringify(user),
                  '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
                ).toString()
              };
              
              fetch(`${process.env.REACT_APP_SSOURL}/api/auth/oauth2/validateToken`, {
                method: 'POST',
                headers: {
                  Authorization: localStorage.getItem('ssojwttoken'),
                  'Content-Type': 'application/json',
                  Accept: 'application/json'
                },
                body: JSON.stringify(encuser)
              })
                .then((data) => {
                  if(data.status === 200) {
                    if (localStorage.getItem('token')) {
                    }
                  }
                  else {
                    removeUserSession()
                  }
              });


          }, MINUTE_MS);        
          return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, []);
   // to get the logo
   function getLogo(){

    const token = localStorage.getItem('token');
    const url = decodeURI(
      `${process.env.REACT_APP_SSOURL}/api/application/pagesOfRole?roleName=${localStorage.getItem('ssoRole')}&applicationName=${localStorage.getItem('appName')}&clientName=${localStorage.getItem('clientIDE')}`
    );
    fetch(url, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          console.log('decrypted assined data', data);
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
         // setAssignedPages(decryptedData);
          console.log('client logo url', decryptedData);
          setLogoImage(decryptedData[0].clientData.clientLogo);

          const regex = /\/uploads\/(.*)/;
          const match = decryptedData[0].clientData.clientLogo.match(regex);

          const extractedTextLogo = match ? match[1] : null;
          console.log('/uploads/' + extractedTextLogo);
          setLogoImageExtrcted('/uploads/' + extractedTextLogo);

         
          const fullImageUrl = new URL(('/uploads/' + extractedTextLogo), `${process.env.REACT_APP_SSOURL}`).toString();

          setFullUrl(fullImageUrl);

        }
      })
      .catch((err) => console.log('err', err));


   }
  
    return (
        <React.Fragment>
            <div className={classes.boxContainer}>
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />                    
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }} className="iconManage">
                    <Avatar variant="rounded" className={classes.headerAvatar} onClick={handleLeftDrawerToggle} color="inherit">
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </div>
            <SearchSection theme="light" />
            <div className={classes.grow} />
            <div className={classes.grow} />
            <div className='title'>
                {user_name}
            </div>
            <div align="center" className='clientLogo'>
             
                   <div align="left" className="imageDiv">
         
              <Image src={fullUrl} style={{ width: '60%',height: '53px',marginLeft: '45px',marginRight: '45px',padding: '0px'}}/>  
          {/*     <Box
                    component="img"
                    sx={{
                      height: '53px',
                      width: '60%',
                      marginLeft: '45px',
                      marginRight: '45px',
                      padding: '0px'
                     // maxHeight: { xs: 233, md: 167 },
                    //  maxWidth: { xs: 350, md: 250 },
                    }}
                    alt="logo"
                    src={fullUrl}
              /> */}
            </div> 
                </div>
            <ProfileSection />
        </React.Fragment>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
