import { dashboard } from './dashboard';
import { pages } from './pages';
import { utilities } from './utilities';
import { other } from './other';

//-----------------------|| MENU ITEMS ||-----------------------//
// items: [dashboard, pages, utilities, other]
const menuItems = {
    items: [dashboard, pages]
};

export default menuItems;
