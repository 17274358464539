import Cookies from 'js-cookie'
import * as CryptoJS from 'crypto-js';

export const setUserSession = (token, clientIDE, appName, clientID, role, userId, redirectUri, ssojwttoken, oAuthtoken, permissions, firstName, lastName, ssoRole,userName, email) => {
  // localStorage.setItem('email', email);
  // localStorage.setItem('firstName', firstName);
  // localStorage.setItem('lastName', lastName);
  // localStorage.setItem('phoneNumber', phoneNumber);
  // localStorage.setItem('_id', _id);
  localStorage.setItem('token', token);
  localStorage.setItem('clientIDE', clientIDE);
  localStorage.setItem('appName', appName);
  localStorage.setItem('clientID', clientID);
  localStorage.setItem('role', role);
  localStorage.setItem('userId', userId);
  localStorage.setItem('redirectUri', redirectUri);
  localStorage.setItem('ssojwttoken', ssojwttoken);
  localStorage.setItem('oAuthtoken', oAuthtoken);
  localStorage.setItem('permissions', permissions);

  localStorage.setItem('firstName', firstName);
  localStorage.setItem('lastName', lastName);
  localStorage.setItem('ssoRole', ssoRole);
  localStorage.setItem('userName', userName);
  localStorage.setItem('email', email);
  

};

export const removeUserSession = () => {
  localStorage.removeItem('email');
  localStorage.removeItem('firstName');
  localStorage.removeItem('lastName');
  localStorage.removeItem('phoneNumber');
  localStorage.removeItem('token');
  localStorage.removeItem('_id');
  localStorage.removeItem('permissions');
  localStorage.removeItem('redirectUri');
  localStorage.removeItem('role');
  localStorage.removeItem('oAuthtoken');
  localStorage.removeItem('clientID');
  localStorage.removeItem('clientIDE');
  localStorage.removeItem('ssojwttoken');
  localStorage.removeItem('userId');
  localStorage.removeItem('appName');
  localStorage.removeItem('userName');
  localStorage.removeItem('ssoRole');
};

export const getUser = () => {
  const email = localStorage.getItem('email');
  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const user = {
    email,
    firstName,
    lastName
  };
  return user;
};

// export const getToken = () => localStorage.getItem('token') || false;

export const getToken = () => {
  // const cookie = Cookies.get('testCookie')
  // if (!cookie) {
  //   removeUserSession();
  // }

  if (localStorage.getItem('token')) {
    return true
  }
};

// export const commonPortalPermissions = async () => {
//   const permissionsdata = {
//     "userId": "61c581dc5796503c36f5dbc7",
//     "appName": localStorage.getItem('appName'),
//     "clientName": localStorage.getItem('clientIDE')
//   }
//   let pageStatus = await fetch(`https://cpuatapi.europ-assistance.in/api/sso/roleAndPagesOfUser`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       Accept: 'application/json'
//     },
//     body: JSON.stringify(permissionsdata)
//   }).then((res) => res.json())
//     .then((data) => {
//       if (data.success == true) {
//         return data.data[0].roleData
//       }
//     })
//   return pageStatus
// }

// export const getToken = () => {
//   return localStorage.getItem('token');
// };
