import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import { getToken } from '../utils/Auth';
// project imports
import config from './../config';

//-----------------------|| ROUTING RENDER ||-----------------------//

const Routes = () => {
    return (
        <Switch>
            <Redirect exact from="/" to={config.defaultPath} />
            <React.Fragment>
                {/* Routes for authentication pages */}
                {/* {
                    getToken() ? <MainRoutes /> : ''
                } */}
                {/* Routes for main layouts */}
                {/* <Redirect exact from="/" to={config.defaultPath} /> */}
                <MainRoutes />
                <AuthenticationRoutes /> 
            </React.Fragment>
        </Switch>
    );
};

export default Routes;
