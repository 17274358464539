import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@material-ui/core';

// project imports
import config from './../../../config';
import Logo from './../../../ui-component/Logo';
import mainLogo from'../../../assets/images/logo.svg';
import tataLogo from'../../../assets/images/tataaig.jpg';
import './index.css';
//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {
    return (
        <ButtonBase disableRipple>
            {/* <Logo /> */}
            <div align="left" className="imageDiv">
                <img src={mainLogo} width='40%'/>
                {/* <img src={tataLogo} width='80%'/> */}
                {/* 32 32 32 32 32 32 */}
         
            </div>
        </ButtonBase>
    );
};

export default LogoSection;

{/* <ButtonBase disableRipple component={Link} to={config.defaultPath}></ButtonBase> */}