import React from "react";
import { useState, useEffect } from 'react';
import { Route, Redirect } from "react-router-dom";
import { getToken } from '../utils/Auth';
import { removeUserSession, getUser } from '../utils/Auth';
// import { commonPortalPermissions } from '../utils/Auth';
import config from './../config';
import * as CryptoJS from 'crypto-js';
export const ProtectedRoute = ({
  component: Component,
  ...rest
}) => {
  
  const [pageTest, setPageTest] = useState(true);
  const permissionsdata = {
    "userId": localStorage.getItem('userId'),
    "appName": localStorage.getItem('appName'),
    "clientName": localStorage.getItem('clientIDE')
  }
  const roleName = localStorage.getItem('role')
  const applicationName = localStorage.getItem('appName')
  const clientName = localStorage.getItem('clientIDE')  
  const token = localStorage.getItem('ssojwttoken')
  fetch(`${process.env.REACT_APP_SSOURL}/api/application/pagesOfRole?roleName=${roleName}&applicationName=${applicationName}&clientName=${clientName}`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: token
      }
  }).then((res) => res.json())
  .then((data) => {
    if (data.code === 200) {
      const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      let pages = decryptedData[0].pageData
      // console.log(pages, 'pages')
      let path = rest.location.pathname
      let permissions = {
        dashboard: pages.some(e => e.page === 'Dashboard'),
        MemberEnquiry: pages.some(e => e.page === 'Member Enquiry'),
        Reports: pages.some(e => e.page === 'Reports'),
        ClaimsOverView: pages.some(e => e.page === ''),
        ClaimLog: pages.some(e => e.page === 'Claims Log'),
        reviewdServices: pages.some(e => e.page === 'Reviewd Services'),
        ClaimHistory: pages.some(e => e.page === 'ClaimHistory'),
        DenialHistory: pages.some(e => e.page === 'DenialHistory'),
        OpinionHistory: pages.some(e => e.page === 'OpinionHistory'),
        InvestigationHistory: pages.some(e => e.page === 'InvestigationHistory'),
        PaymentconfirmationHistory: pages.some(e => e.page === 'PaymentconfirmationHistory'),
        InvoiceApprovalsTab: pages.some(e => e.pageName === 'InvoiceApprovalsTab'),
        DenialApprovalsTab: pages.some(e => e.pageName === 'DenialApprovalsTab'),
        DenialLettersTab: pages.some(e => e.pageName === 'DenialLettersTab'),
        RepudiationLettersTab: pages.some(e => e.pageName === 'RepudiationLettersTab'),
        ReOpenClaimsTab: pages.some(e => e.pageName === 'ReOpenClaimsTab'),
        OpenionClaimsTab: pages.some(e => e.pageName === 'OpenionClaimsTab'),
        InvestigationClaimsTab: pages.some(e => e.pageName === 'InvestigationClaimsTab'),
        PolicyConfirmationTab: pages.some(e => e.pageName === 'PolicyConfirmationTab')
      }
      // console.log(permissions, 'permissions')
      localStorage.removeItem('permissions');
      localStorage.setItem('permissions', JSON.stringify(permissions));
      // console.log(path, 'path')
      if(path === '/dashboard/default' ) {
        setPageTest(pages.some(e => e.page === 'Dashboard'))        
      }
      if(path === '/MemberEnquiry' ) {
        setPageTest(pages.some(e => e.page === 'Member Enquiry'))
      }
      if(path === '/Reports' ) {
        setPageTest(pages.some(e => e.page === 'Reports'))
      }
      if(path === '/ClaimEnquiry' ) {
        setPageTest(pages.some(e => e.page === 'Claim Overview'))
      }
      if(path === '/claims-log' ) {
        setPageTest(pages.some(e => e.page === 'Claims Log'))
      }
      if(path === '/reviewdServices' ) {
        setPageTest(pages.some(e => e.page === 'Reviewd Services'))
      }
      if(path === '/claimHistory' ) {
        setPageTest(pages.some(e => e.page === 'ClaimHistory'))
      }
      if(path === '/denialHistory' ) {
        setPageTest(pages.some(e => e.page === 'DenialHistory'))
      }
      if(path === '/opinionHistory' ) {
        setPageTest(pages.some(e => e.page === 'OpinionHistory'))
      }
      if(path === '/investigationHistory' ) {
        setPageTest(pages.some(e => e.page === 'InvestigationHistory'))
      }
      if(path === '/paymentconfirmationHistory' ) {
        setPageTest(pages.some(e => e.page === 'PaymentconfirmationHistory'))
      }
    }
    
  })
  const user = {
    "clientId": localStorage.getItem('clientID'),
    "redirectUri": localStorage.getItem('redirectUri'),
    "userId": localStorage.getItem('userId'),
    "token": localStorage.getItem('oAuthtoken')
  }
  const encuser = {
    encryptedData: CryptoJS.AES.encrypt(
      JSON.stringify(user),
      '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
    ).toString()
  };
  fetch(`${process.env.REACT_APP_SSOURL}/api/auth/oauth2/validateToken`, {
    method: 'POST',
    headers: {
      Authorization: localStorage.getItem('ssojwttoken'),
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(encuser)
  })
    .then((data) => {
      if(data.status === 200) {
        if (localStorage.getItem('token')) {
        }
      }
      else {
        removeUserSession()
      }
  });
  // fetch(`https://cpuatapi.europ-assistance.in/api/sso/roleAndPagesOfUser`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Accept: 'application/json'
  //   },
  //   body: JSON.stringify(permissionsdata)
  // }).then((res) => res.json())
  //   .then((data) => {
  //     if (data.success == true) {

  //       console.log(data, 'permissions data ')
        
  //       let pages = data.data[0].roleData[0].pageData;
  //       let path = rest.location.pathname
        

  //       let permissions = {
  //         dashboard: pages.some(e => e.pageName === 'Dashboard'),
  //         MemberEnquiry: pages.some(e => e.pageName === 'Member Enquiry'),
  //         Reports: pages.some(e => e.pageName === 'Reports'),
  //         ClaimsOverView: pages.some(e => e.pageName === 'Claim Overview'),
  //         ClaimLog: pages.some(e => e.pageName === 'Claims Log'),
  //         reviewdServices: pages.some(e => e.pageName === 'Reviewd Services'),
  //         ClaimHistory: pages.some(e => e.pageName === 'ClaimHistory'),
  //         DenialHistory: pages.some(e => e.pageName === 'DenialHistory'),
  //       }

        
  //       localStorage.removeItem('permissions');
  //       localStorage.setItem('permissions', JSON.stringify(permissions));
  //       if(path === '/dashboard/default' ) {
  //         setPageTest(pages.some(e => e.pageName === 'Dashboard'))
  //       }
  //       if(path === '/MemberEnquiry' ) {
  //         setPageTest(pages.some(e => e.pageName === 'Member Enquiry'))
  //       }
  //       if(path === '/Reports' ) {
  //         setPageTest(pages.some(e => e.pageName === 'Reports'))
  //       }
  //       if(path === '//ClaimEnquiry' ) {
  //         setPageTest(pages.some(e => e.pageName === 'Claim Overview'))
  //       }
  //       if(path === '/claims-log' ) {
  //         setPageTest(pages.some(e => e.pageName === 'Claims Log'))
  //       }
  //       if(path === '/reviewdServices' ) {
  //         setPageTest(pages.some(e => e.pageName === 'Reviewd Services'))
  //       }
  //     }
      
  //   })

  return (
    <Route
      {...rest}
      render={props => {
        if (pageTest === false) {
          return <Redirect exact from="/" to={'/pages/pagenotfound'} />
        }
        if (getToken()) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect exact from="/" to={'/pages/login/authsso'} />
          );
        }
      }}
    />
  );
};
