// assets
import { IconUsers, IconLockAccess, IconSquarePlus, IconReport, IconSettingsAutomation, IconHistory, IconDeviceAnalytics, IconUserCheck, IconCardboards, IconLiveView, IconAlertCircle, IconKey, IconReceipt2, IconBug, IconBellRinging, IconPhoneCall, IconDownload } from '@tabler/icons';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

// constant
const icons = {
    IconKey: IconKey,
    IconReceipt2: IconReceipt2,
    IconBug: IconBug,
    IconBellRinging: IconBellRinging,
    IconPhoneCall: IconPhoneCall,
    ViewModuleIcon: ViewModuleIcon,
    IconAlertCircle: IconAlertCircle,
    IconLiveView: IconLiveView,
    IconCardboards: IconCardboards,
    IconUserCheck: IconUserCheck,
    IconDeviceAnalytics: IconDeviceAnalytics,
    IconHistory: IconHistory,
    IconSettingsAutomation: IconSettingsAutomation,
    IconReport: IconReport,
    IconSquarePlus: IconSquarePlus,
    IconLockAccess: IconLockAccess,
    IconUsers: IconUsers,
    IconDownload: IconDownload
};

//-----------------------|| EXTRA PAGES MENU ITEMS ||-----------------------//

export const pages = {
    id: 'pages',
    title: '',
    caption: 'Pages Caption',
    type: 'group',
    children: [
        // {
        //     id: 'ClaimsOverview',
        //     title: 'Claims Overview',
        //     type: 'item',
        //     url: '/dashboard/default',
        //     icon: icons['IconCardboards'],
        //     breadcrumbs: false
        // },
        // {
        //     id: 'article',
        //     title: 'Article',
        //     type: 'item',
        //     url: '/article',
        //     icon: icons['IconCardboards'],
        //     breadcrumbs: false
        // },
        {
            id: 'ClaimsOverView',
            title: 'Claim OverView',
            type: 'item',
            url: '/ClaimEnquiry',
            icon: icons['IconHistory'],
            breadcrumbs: false
        },        
        {
            id: 'MemberEnquiry',
            title: 'Member Enquiry',
            type: 'item',
            url: '/MemberEnquiry',
            icon: icons['IconUsers'],
            breadcrumbs: false
        },
        // {
        //     id: 'History',
        //     title: 'History',
        //     type: 'item',
        //     url: '/dashboard/default',
        //     icon: icons['IconLiveView'],
        //     breadcrumbs: false
        // },
        {
            id: 'ClaimLog',
            title: 'Claim Log',
            type: 'item',
            url: '/claims-log',
            icon: icons['IconReport'],
            breadcrumbs: false
        },
        {
            id: 'reviewdServices',
            title: 'Reviewed Invoices',
            type: 'item',
            url: '/reviewdServices',
            icon: icons['IconLiveView'],
            breadcrumbs: false
        },
        {
            id: 'ClaimHistory',
            title: 'Claim History',
            type: 'item',
            url: '/claimHistory',
            icon: icons['IconHistory'],
            breadcrumbs: false
        },
        {
            id: 'DenialHistory',
            title: 'Denial History',
            type: 'item',
            url: '/denialHistory',
            icon: icons['IconDownload'],
            breadcrumbs: false
        },
        {
            id: 'OpinionHistory',
            title: 'Opinion History',
            type: 'item',
            url: '/opinionHistory',
            icon: icons['IconDownload'],
            breadcrumbs: false
        },
        {
            id: 'InvestigationHistory',
            title: 'Investigation History',
            type: 'item',
            url: '/investigationHistory',
            icon: icons['IconDownload'],
            breadcrumbs: false
        },
        {
            id: 'PaymentconfirmationHistory',
            title: 'Payment Confirmation History',
            type: 'item',
            url: '/paymentconfirmationHistory',
            icon: icons['IconDownload'],
            breadcrumbs: false
        },
        {
            id: 'Reports',
            title: 'Reports',
            type: 'item',
            url: '/Reports',
            icon: icons['IconDeviceAnalytics'],
            breadcrumbs: false
        },
        // {
        //     id: 'Reporting',
        //     title: 'Reporting',
        //     type: 'item',
        //     url: '/dashboard/default',
        //     icon: icons['IconDeviceAnalytics'],
        //     breadcrumbs: false
        // },
        // {
        //     id: 'CompanyCreation',
        //     title: 'Company Creation',
        //     type: 'item',
        //     url: '/dashboard/default',
        //     icon: icons['IconSquarePlus'],
        //     breadcrumbs: false
        // },
        // {
        //     id: 'UserAccessRight',
        //     title: 'User Access Right',
        //     type: 'item',
        //     url: '/dashboard/default',
        //     icon: icons['IconLockAccess'],
        //     breadcrumbs: false
        // },
        // {
        //     id: 'Setting',
        //     title: 'Setting',
        //     type: 'item',
        //     url: '/dashboard/default',
        //     icon: icons['IconSettingsAutomation'],
        //     breadcrumbs: false
        // },
        // {
        //     id: 'authentication',
        //     title: 'Authentication',
        //     type: 'collapse',
        //     icon: icons['IconKey'],
        //     children: [
        //         {
        //             id: 'login3',
        //             title: 'Login',
        //             type: 'item',
        //             url: '/pages/login/login3',
        //             target: false
        //         },
        //         {
        //             id: 'register3',
        //             title: 'Register',
        //             type: 'item',
        //             url: '/pages/register/register3',
        //             target: false
        //         }
        //     ]
        // }
    ]
};
