import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Loadable from '../ui-component/Loadable';

// project imports
import MinimalLayout from './../layout/MinimalLayout';

// login option 3 routing
const AuthSSOLogin = Loadable(lazy(() => import('../views/pages/authentication/authentication3/AuthSSOLogin')));
const AuthLogin3 = Loadable(lazy(() => import('../views/pages/authentication/authentication3/Login3')));
const AuthLogout = Loadable(lazy(() => import('../views/pages/authentication/authentication3/Logout')));

const PageNotFound = Loadable(lazy(() => import('../views/pages/authentication/authentication3/PageNotFound')));
const SSOLogin = Loadable(lazy(() => import('../views/pages/authentication/authentication3/SSOLogin')));
const AuthRegister3 = Loadable(lazy(() => import('../views/pages/authentication/authentication3/Register3')));

//-----------------------|| AUTHENTICATION ROUTING ||-----------------------//

const AuthenticationRoutes = () => {
    const location = useLocation();

    return (
        <Route path={['/pages/login/authsso', '/pages/login/sso', '/pages/login/login3', '/pages/login/logout', '/pages/register/register3', '/pages/pagenotfound']}>
            <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                    <Route path="/pages/login/authsso" component={AuthSSOLogin} />
                    <Route path="/pages/login/login3" component={AuthLogin3} />
                    <Route path="/pages/login/logout" component={AuthLogout} />
                    <Route path="/pages/login/sso" component={SSOLogin} />                    
                    <Route path="/pages/register/register3" component={AuthRegister3} />
                    <Route path="/pages/pagenotfound" component={PageNotFound} />
                </Switch>
            </MinimalLayout>
        </Route>
    );
};

export default AuthenticationRoutes;
